<template>
  <v-dialog persistent height="450" max-width="30%" v-model="show">
       <v-card>
       <v-card-title
          class="headline grey lighten-2" >
          Resend Email
        </v-card-title>
        <v-layout justify-start class="ml-5 mt-2" column>
          <p>Please re-enter your registered email.</p>
          <v-text-field outlined dense class="mr-5 mb-n1 mt-n1" placeholder="Registered email here" v-model="email"></v-text-field>
        </v-layout>
        <v-layout justify-end class="mt-2 mr-5">
          <v-btn text gray--text small class="mr-4 mb-2" @click="$emit('close')">Cancel</v-btn>
          <v-btn small class="accent" @click="resendEmail">Submit</v-btn>

        </v-layout>
       </v-card>
  </v-dialog>
</template>

<script>
export default {

  props: ['show'],

  data: () => ({
    email: '',
  }),

  methods: {
    resendEmail: function(){
      this.$store.dispatch('resendVerificationEmail', this.email)
    }
  }



    

}
</script>

<style>

</style>